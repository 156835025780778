







































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_SEARCH } from '../store';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { ApiResponse } from '@/components/types';
import EmployeeSelect from '@/modules/employee/components/EmployeeSelect.vue';
import EditAppointmentDialog from '../components/EditAppointmentDialog.vue';
import CancelAppointmentDialog from '../components/CancelAppointmentDialog.vue';
import { Appointment, AppointmentView, SearchParams } from '@/modules/appointment/types';
import { MapStateToView } from '@/modules/appointment/model';
import { AuthorizationMixin } from '@/views/mixin';
import DatePicker from '@/components/form/DatePicker.vue';
import { routeToLocation } from '@/router';
import SearchDownloadAction from "@/modules/appointment/components/SearchDownloadAction.vue";

const Appointment = namespace('appointment');

const TableView = () => import('../components/PluginTable.vue');
const ListView = () => import('../components/PluginList.vue');

@Component({
  components: {
    SearchDownloadAction,
    DatePicker,
    EmployeeSelect,
    ErrorAlert,
    EditAppointmentDialog,
    CancelAppointmentDialog,
    TableView,
    ListView
  }
})
export default class Search extends AuthorizationMixin {
  @Appointment.Action(FETCH_SEARCH) fetch!: (search: SearchParams) => Promise<ApiResponse<Appointment[]>>;

  loading: boolean = false;
  results: Appointment[] = [];
  error: any = null;
  searched: boolean = false;

  get employee(): string {
    return this.$route.query['employee'] as string;
  }

  set employee(employee: string) {
    if (!employee) {
      const { employee, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, employee } })).catch(() => {
    });
  }

  get date(): string {
    return this.$route.query['date'] as string || '';
  }

  set date(date: string) {
    if (!date) {
      const { date, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, date } })).catch(() => {
    });
  }

  get search(): string {
    return this.$route.query['search'] as string;
  }

  set search(search: string) {
    if (!search) {
      const { search, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, search } })).catch(() => {
    });
  }

  get editAppointment(): string {
    return this.$route.query['editAppointment'] as string;
  }

  set editAppointment(editAppointment: string) {
    if (!editAppointment) {
      const { editAppointment, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, editAppointment } })).catch(() => {
    });
  }

  get cancelAppointment(): string {
    return this.$route.query['cancelAppointment'] as string;
  }

  set cancelAppointment(cancelAppointment: string) {
    if (!cancelAppointment) {
      const { cancelAppointment, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, cancelAppointment } })).catch(() => {
    });
  }

  get items(): AppointmentView[] {

    return this.results
      .sort((first, second) => first.start.localeCompare(second.start))
      .map((appointment) => MapStateToView(appointment));
  }

  reset() {
    this.search = '';
    this.date = '';
    this.employee = '';

    this.loading = false;
    this.results = [];
  }

  async searchAppointments() {
    this.error = null;
    this.searched = false;

    this.loading = true;
    const { error, content } = await this.fetch({ search: this.search, date: this.date, employee_id: this.employee });
    this.loading = false;

    if (error) {
      this.error = error;

      return;
    }

    this.searched = true;

    if (!content) return;

    this.results = content;
  }

  created() {
    if (!this.search && !this.date && !this.employee) return;

    return this.searchAppointments();
  }
}
