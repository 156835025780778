






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SearchDownloadAction extends Vue {
  @Prop({ type: String, required: false }) employee!: string;
  @Prop({ type: String, required: false }) search!: string;
  @Prop({ type: String, required: false }) date!: string;
  @Prop({ type: String, default: 'primary' }) color!: string;
  @Prop({ type: Boolean, default: true }) icon!: boolean;

  error: any = null;
  loading: boolean = false;

  get filename() {
    return `Termine.pdf`;
  }

  async download() {
    this.loading = true;

    try {
      const { data } = await this.$http.get(`api/appointment/search-pdf`, {
        responseType: 'blob',
        params: {
          'employee_id': this.employee,
          'search': this.search,
          'date': this.date
        }
      });

      this.loading = false;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.setAttribute('download', this.filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error)
      this.error = error;
      this.loading = false;
      return;
    }
  }
}
